import styled from 'styled-components'

import Banner from 'images/banners/banner-perguntas.svg'

export const BannerPerguntas = styled.section`
  background: url(${Banner}) no-repeat bottom center;
  height: 261px;
  padding-top: 62px;
  .title-icon {
    display: flex;
    align-items: center;
    margin-top: 26px;
    img {
      margin-right: 21px;
    }
    h2 {
      font: normal bold 48px/55px 'DepotNew';
      color: #ffffff;
    }
  }
  @media (max-width: 1050px) {
    .title-icon {
      justify-content: center;
    }
  }
  @media (max-width: 480px) {
    padding: 40px 0px;
    height: auto;
    .title-icon {
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 28px;
        line-height: 1;
      }
      img {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
`

export const SectionFaq = styled.section`
  padding-top: 75px;
  padding-bottom: 83px;
  .all {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    align-items: flex-start;
  }
  .duvidas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 99px;
    span {
      font: normal normal 20px/26px 'DepotNew';
      color: #636466;
      margin-bottom: 15px;
    }
    a {
      width: 328px;
      height: 60px;
      background: #0ac4ac;
      border: 2px solid #0ac4ac;
      border-radius: 8px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font: normal 16px/1 'DepotNew';
      color: #ffffff;
      transition: all 0.3s;
      &:hover {
        background-color: transparent;
        color: #0ac4ac;
        transition: all 0.3s;
      }
    }
  }
  @media (max-width: 1050px) {
    height: auto;
    padding: 40px 0px;
    .all {
      grid-template-columns: 1fr;
      .makeStyles-title_pergunta-4 {
        max-width: 240px;
        font-size: 14px;
        line-height: 20px;
      }
      .makeStyles-resposta-5 {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .duvidas {
      margin-top: 20px;
    }
  }
`
