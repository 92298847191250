import styled from 'styled-components'

import { Link } from 'gatsby'

export const StyleNavigation = styled.div`
  margin-top: 80px;
  width: 100%;
  height: 90px;
  background: #008aca;
  display: flex;
  align-items: center;
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-start;
  }
  @media (max-width: 480px) {
    margin-top: 60px;
    height: auto;
    ul {
      width: 100%;
      grid-template-columns: 1fr 1fr;
    }
    .container {
      padding: 0;
    }
  }
`

export const BtnNavegacao = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
  font-family: 'DepotNew';
  background-color: transparent;
  text-align: center;
  transition: all 0.3s;
  &.active {
    font-weight: bold;
    color: #ffffff;
    background-color: #0095da;
    &:after {
      content: '';
      background-color: #06cfb6;
      border-radius: 100px 100px 0px 0px;
      width: 100%;
      height: 7px;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 1;
      transition: all 0.3s;
    }
  }
  &:hover {
    background-color: #0095da;
    color: #ffffff;
    transition: all 0.3s;
  }
  @media (max-width: 480px) {
    height: 70px;
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
`
