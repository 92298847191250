import React, { Component } from 'react'
import { Link } from 'gatsby'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-pages'
import Demo from 'components/s-demo/main'
import Footer from 'components/footer/main'
import SEO from 'components/seo'
import Accordion from 'components/accordion'

import { BannerPerguntas, SectionFaq } from '../../Style/perguntas-frequentes'
import { Container, TitleBanner } from '../../Style/global'

import IconePerguntas from 'images/icone-perguntas-blue.svg'

// Sass Global
import 'sass/main.scss'

class PagePerguntas extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Perguntas Frequentes" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerPerguntas>
          <Container>
            <TitleBanner>dúvidas</TitleBanner>
            <div className="title-icon">
              <img src={IconePerguntas} alt="Icone perguntas" />
              <h2>Perguntas Frequentes</h2>
            </div>
          </Container>
        </BannerPerguntas>

        <SectionFaq>
          <Container>
            <div className="all">
              <Accordion
                title_pergunta="QUANTO CUSTA O SISTEMA INOVAFARMA?"
                resposta="O valor da mensalidade varia conforme a quantidade de computadores, número de colaboradores, porte da farmácia e faturamento mensal. Entre em contato com um de nossos especialistas para uma melhor avaliação. "
              />
              <Accordion
                title_pergunta="COMO FUNCIONA A FORMA DE PAGAMENTO?"
                resposta="Todo mês a farmácia recebe um boleto com o valor da mensalidade do sistema, que pode ser pago em agências bancárias, casas lotéricas, aplicativos de pagamento ou internet bank. Caso preferir a farmácia também pode realizar transferência bancária."
              />
              <Accordion
                title_pergunta="TENHO QUE PAGAR UM VALOR DE IMPLANTAÇÃO?"
                resposta="A farmácia negocia o valor da implantação com a revenda autorizada da sua região. São levados em consideração: infraestrutura da farmácia (condições dos computadores, configurações), número de funcionários, tempo de treinamento, deslocamento e estadia.  "
              />
              <Accordion
                title_pergunta="O QUE ACONTECE SE A FARMÁCIA NÃO PAGAR OU ATRASAR A ASSINATURA DO SISTEMA?"
                resposta="Caso a farmácia atrase o pagamento da assinatura, o sistema fica disponível por 3 dias para uso. Após esse período, se o pagamento não for confirmado, o acesso as ferramentas do sistema são bloqueadas. Assim que a farmácia efetuar e comprovar o pagamento da assinatura, o acesso é liberado automaticamente. "
              />
              <Accordion
                title_pergunta="ONDE POSSO COMPRAR O SISTEMA?"
                resposta="Temos revendas autorizadas por todo o Brasil. Para sua comodidade, solicite uma demonstração do sistema para que nossos especialistas entrem em contato com sua farmácia e agendem o melhor horário para falar com você. "
              />
              <Accordion
                title_pergunta="COMO FUNCIONA O SUPORTE DO SISTEMA?"
                resposta="A farmácia pode entrar em contato com revenda autorizada da sua região. Nos dias úteis da semana, de segunda a sexta feira, o suporte fica disponível das 08:00 às 18:00 horas. Em casos de urgência, temos plantão das 19:00 às 22:00.
                  Aos sábados o atendimento fica disponível das 08:00 às 12:00. Após as 14:00 até as 18:00 temos a disponibilidade de plantonista para urgências. "
              />
              <Accordion
                title_pergunta="COMO FUNCIONA A ASSINATURA DO INOVAFARMA?"
                resposta="Para ter acesso as funções e ferramentas do sistema a farmácia paga uma assinatura mensal. "
              />
              <Accordion
                title_pergunta="MINHA FARMÁCIA VAI TER ALGUM TIPO DE TREINAMENTO?"
                resposta="Nossas revendas autorizadas fornecem treinamento presencial quando é implantado o sistema na sua farmácia. Além disso, todo cliente possui acesso exclusivo 24 horas por dia 7 dias por semana na Universidade INOVAFARMA. Essa plataforma online oferece treinamento das ferramentas do sistema divididos por função dentro da farmácia. Por exemplo: o Balconista pode aprender sobre o módulo de Vendas, enquanto que o Farmacêutico aprende como usar o módulo SNGPC. Dessa forma você pode criar rotinas de treinamento para sua equipe. "
              />
            </div>
            <div className="duvidas">
              <span>Não encontrou sua dúvida? </span>
              <Link to="/sobre/contato">Entre em contato</Link>
            </div>
          </Container>
        </SectionFaq>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PagePerguntas
