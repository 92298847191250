import React, { Component } from 'react'

import { StyleNavigation, BtnNavegacao } from './style'

class ComponentNavigationPage extends Component {
  render() {
    return (
      <StyleNavigation>
        <div className="container">
          <ul>
            <li>
              <BtnNavegacao to={`/sobre/`} className={this.props.from === '/sobre/' ? 'active' : null} activeClassName="active">
                Quem somos{' '}
              </BtnNavegacao>
            </li>
            <li>
              <BtnNavegacao
                to={`/sobre/contato`}
                className={this.props.from === '/sobre/contato/' ? 'active' : null}
                activeClassName="active"
              >
                Contato{' '}
              </BtnNavegacao>
            </li>
            <li>
              <BtnNavegacao
                to={`/sobre/representante`}
                className={this.props.from === '/sobre/representante/' ? 'active' : null}
                activeClassName="active"
              >
                Quero ser representante{' '}
              </BtnNavegacao>
            </li>
            <li>
              <BtnNavegacao
                to={`/sobre/perguntas-frequentes`}
                className={this.props.from === '/sobre/perguntas-frequentes/' ? 'active' : null}
                activeClassName="active"
              >
                Perguntas Frequentes{' '}
              </BtnNavegacao>
            </li>
          </ul>
        </div>
      </StyleNavigation>
    )
  }
}

export default ComponentNavigationPage
